import { createTheme, PaletteColorOptions } from '@mui/material';
import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import { Shadows } from '@mui/material/styles/shadows';

declare module '@mui/material/styles' {
  interface Palette {
    pressPayGreen: PaletteColorOptions;
  }

  interface PaletteOptions {
    pressPayGreen: PaletteColorOptions;
  }
}
const { palette } = createTheme();

let baseTheme = createTheme({
  palette: {
    background: {
      default: '#fafafa',
    },
    primary: {
      main: '#ff0094',
    },
    secondary: {
      main: '#001E5A',
    },
    error: {
      main: red[600],
      light: red[50],
    },
    pressPayGreen: palette.augmentColor({
      color: {
        main: '#b8d3d3',
      },
    }),
  },
  typography: {
    allVariants: {
      fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  },
});

const theme = createTheme(baseTheme, {
  palette: {
    ...baseTheme.palette,
    footer: {
      backgroundColor: '#282828',
      color: '#fff',
    },
    footerCopyright: {
      backgroundColor: '#202020',
      color: '#fff',
    },
  },
  shadows: baseTheme.shadows.map(() => 'none') as Shadows,
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1,
      textAlign: 'center',
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '2.3rem',
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '3.2rem',
        textAlign: 'left',
      },
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: 400,
      lineHeight: 1,
      [baseTheme.breakpoints.up('sm')]: {
        fontSize: '1.4rem',
      },
      [baseTheme.breakpoints.up('md')]: {
        fontSize: '1.6rem',
      },
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
          margin: 0,
          fontSize: '1.1rem',
        },
        '#___gatsby': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
        '#gatsby-focus-wrapper': {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${baseTheme.spacing(2)}!important`,
          backgroundColor: grey.A100,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          notchedOutline: {
            borderColor: grey[400],
          },
          // '&.Mui-focused': {
          //   '& .MuiOutlinedInput-notchedOutline': {
          //     borderWidth: '1px',
          //   },
          // },
        },
      },
    },
  },
});

export default theme;
