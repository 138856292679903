import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { useIntl } from 'gatsby-plugin-react-intl';
import React, { type FC } from 'react';

const color = 'secondary.contrastText';

const Footer: FC = (props) => {
  const intl = useIntl();

  const helpUrl = intl.formatMessage({
    description: 'Help url',
    defaultMessage: 'https://presspay.zendesk.com/hc/en-au',
  });
  const termsOfServiceUrl = intl.formatMessage({
    description: 'Terms of service url',
    defaultMessage: 'https://presspay.com.au/terms-of-service/',
  });
  const privacyPolicy = intl.formatMessage({
    description: 'Privacy policy url',
    defaultMessage: 'https://presspay.com.au/privacy-policy/',
  });

  return (
    <Box sx={{ backgroundColor: 'secondary.main', color, p: 2, flexShrink: 0 }} {...props}>
      <Stack>
        <Stack justifyContent="center" direction="row" sx={{ gap: 2 }}>
          <Link color={color} fontSize="small" underline="hover" href={helpUrl} target="_blank">
            Help
          </Link>
          <Divider color={color} orientation="vertical" variant="middle" flexItem />
          <Link color={color} fontSize="small" underline="hover" href={termsOfServiceUrl} target="_blank">
            Terms
          </Link>
          <Divider color={color} orientation="vertical" variant="middle" flexItem />
          <Link color={color} fontSize="small" underline="hover" href={privacyPolicy} target="_blank">
            Privacy
          </Link>
        </Stack>
        <Typography textAlign="center" color={color} sx={{ mt: 1 }} variant="caption">
          © 2022 PressPay. All rights reserved.
        </Typography>
        <Typography textAlign="center" fontSize={9} variant="caption">
          This site is protected by reCAPTCHA and the Google&nbsp;
          <Link color={color} href="https://policies.google.com/privacy">
            Privacy Policy
          </Link>
          &nbsp;and&nbsp;
          <Link color={color} href="https://policies.google.com/terms">
            Terms of Service
          </Link>
          &nbsp;apply.
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
