import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';
import React from 'react';
import Brand from '../assets/brand.svg';

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#fff',
  borderBottomColor: grey['200'],
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
}));

export default () => (
  <Header component="header">
    <a href="https://presspay.com.au/" title="home">
      <Brand height="2.5rem" />
    </a>
    <nav />
  </Header>
);
